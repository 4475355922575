<template>
  <div class="app-main-content">
    <card :allow="false" :tabs="tabs"
          v-model="tabActive" body-class="overflow-hidden page-card-body">
      <!--      产品-->
      <template v-if="tabActive === '1'">
        <product-list></product-list>
      </template>
      <!--      采购单-->
      <template v-else-if="tabActive === '2'">
        <purchase-list></purchase-list>
      </template>
<!--      报价单-->
      <product-quote v-if="tabActive === '3'"></product-quote>
    </card>
  </div>
</template>

<script>
import purchaseList from "@/components/purchaseList.vue";
import ProductList from "@/view/project/product/product-list.vue";
import ProductQuote from "@/view/project/product/product-quote.vue";

export default {
  components: {ProductQuote, ProductList, purchaseList },
  data() {
    return {
      tabs: [
        {cat_name: '产品', cat_code: '1'},
        {cat_name: '采购单', cat_code: '2'},
        {cat_name: '报价单', cat_code: '3'}
      ],
      tabActive: "1",
      page: {
        page_size: 15
      },
      listQuery: {
        prjoid: this.$route.query.prj_oid,
        keywords: "",
        delivery_status: "",
        order_by: ""
      }
    }
  }
}
</script>


<style lang="less" scoped>

.app-main-content {
  /deep/ .page-card-body {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    // 表格上方的操作栏
    > .body-action {
      display: flex;
      border-bottom: 1px solid #eee;

      .right {
        align-items: center;
        display: flex;
        margin-left: auto;
        margin-bottom: 10px;

        .item {
          margin-left: 20px;

          .label {
            margin-right: 10px;
          }
        }
      }
    }
  }
}
</style>
