<template>
  <div class="product-quote">
    <div class="card-tools-outlet">
      <div></div>
      <div class="right">
        <div class="item" style="margin-right: 20px;">
          <a-input v-model="listQuery.keywords" placeholder="请输入标题" style="width: 140px" class="search-input">
            <a-icon slot="prefix" type="search" />
          </a-input>
        </div>
        <div class="item">
          <a-button class="search" @click="search">查询</a-button>
        </div>
        <div class="item">
          <a-button class="search" @click="exportList">导出</a-button>
        </div>
      </div>
    </div>
    <div class="body-flex" ref="body">
      <div class="body-left-table">
        <a-table
          style="min-width: 900px; width: auto"
          rowKey="oid"
          :data-source="list"
          :pagination="false"
          :customRow="customRow"
          :scroll="{y: height}"
        >
          <a-table-column title="产品名称">
            <template #default="_, row, index">
              <template v-if="row.edit">
                <auto-input
                  free-input
                  v-if="row.newData"
                  :url="isSupplier ? 'pro/retrieve/bysupplier' : '/pro/retrieve'"
                  value-key="oid"
                  label-key="product_name"
                  :label.sync="row.product_name"
                  v-model="row.prooid"
                  @change="(_,item) => changeProduct(index, item)"
                  @blur="blurProduct(index)"
                ></auto-input>
                <a-input v-else v-model="row.product_name"></a-input>
              </template>
              <template v-else>{{row.product_name}}</template>
            </template>
          </a-table-column>
          <a-table-column title="规格">
            <template #default="_, row">
              <a-input :disabled="!newPro" v-if="row.edit && row.newData" v-model="row.tech_info"></a-input>
              <template v-else>{{row.tech_info}}</template>
            </template>
          </a-table-column>
          <a-table-column title="计划数量">
            <template #default="_, row">
              <a-input-number @change="calcPrice(row)" v-if="row.edit && row.newData" v-model="row.schedule_count" :precision="2"></a-input-number>
              <template v-else>{{row.schedule_count}}</template>
            </template>
          </a-table-column>
          <a-table-column title="品牌">
            <template #default="_, row">
              <a-input :disabled="!newPro" v-if="row.edit && row.newData" v-model="row.band"></a-input>
              <template v-else>{{row.band}}</template>
            </template>
          </a-table-column>
          <a-table-column title="币种" width="140px">
            <template #default="_, row">
              <a-select v-if="row.edit" v-model="row.money_type" style="width: 100%">
                <a-select-option v-for="item in cat.money_rate" :key="item.oid" :value="item.cat_code">
                  {{ item.cat_name }}
                </a-select-option>
              </a-select>
              <template v-else>{{getCatNameByCode(row.money_type, 'money_rate')}}</template>
            </template>
          </a-table-column>
          <a-table-column title="单价">
            <template #default="_, row">
              <a-input-number @change="calcPrice(row)" v-if="row.edit" v-model="row.cost_price" :precision="2"></a-input-number>
              <template v-else>{{row.cost_price}}</template>
            </template>
          </a-table-column>
          <a-table-column title="金额" width="90px">
            <template #default="_, row">
              <template>{{row.sale_price}}</template>
            </template>
          </a-table-column>
          <a-table-column title="备注">
            <template #default="_, row">
              <a-input v-if="row.edit" v-model="row.remark"></a-input>
              <template v-else>{{row.remark}}</template>
            </template>
          </a-table-column>
          <a-table-column title="操作" width="130px">
            <template #default="_, row">
              <a-space @click.stop>
                <span class="blue" v-if="row.edit" @click="saveRow(row)" type="primary">
                  {{row.newData ? '新增' : '保存'}}
                </span>
                <span class="blue" v-else @click="() => {
                  row.pre_product_name = row.product_name
                  row.edit = true
                }">编辑</span>
                <span class="blue" v-if="!row.newData" @click="deleteDialog(row.oid)">删除</span>
              </a-space>
            </template>
          </a-table-column>
        </a-table>
      </div>
      <product-detail ref="productDetail" @delete="deletePro(detailOid)" @reload="getList" prj-pro :source-oid="detailOid" :prooid="currentProOid">
        <template #bizlog>
          <bizlog ref="bizlog" :source-oid="detailOid"></bizlog>
        </template>
      </product-detail>
    </div>
  </div>
</template>

<script>
import listMixin from "@/utils/mixins/listMixin";
import AutoInput from "@/components/autoInput.vue";
import catMixin from "@/utils/mixins/catMixin";
import ProductDetail from "@/components/productDetail.vue";
import Bizlog from "@/components/bizlog.vue";
import {fetch} from "@/utils/request";
import lodash from "lodash"

export default {
  components: {Bizlog, ProductDetail, AutoInput},
  mixins: [listMixin, catMixin],
  data() {
    return {
      cat: {
        money_rate: []
      },
      listMixinOptions : {
        url: "prj/product/retrieve/byprj",
      },
      listQuery: {
        keywords: "",
        prjoid: ""
      },
      page: {
        page_size: 9999
      },
      newDataRow: {
        newData: true,
        edit: true,
        product_name: "",
        prooid: "",
        band: "",
        money_type: "",
        sale_price: "",
        cost_price: ""
      },
      assignNewData: {},
      newPro: true,
      detailOid: "",
      currentProOid: "",
      height: 0
    }
  },
  computed: {
    isSupplier() {
      let roles = JSON.parse(localStorage.getItem('company_info')).Roles
      return !!roles.find(item => item.role_code === 'supplier')
    }
  },
  watch: {
    list: {
      immediate: true,
      handler() {
        if ((this.list.length && this.list.at(-1).newData !== true) || this.list.length === 0) {
          this.list.push({...this.newDataRow})
        }
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.calcY()
      window.addEventListener("resize", this.calcY)
    })
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.calcY)
  },
  methods: {
    handleNum(n) {
      if (!Number.isNaN(Number(n))){
        return lodash.round(Number(n), 2)
      }
      return n
    },
    getListQuery() {
      return {
        ...this.listQuery,
        prjoid: this.$route.query.prj_oid
      }
    },
    exportList() {
      if (this.list.length > 0) {
        window.open(this.$toUrl(`/prj/product/export?oids=${this.list.map(item => item.oid).join(',')}`, true, "api"))
      } else {
        this.$message.error('暂无产品')
      }
    },
    calcY: lodash.debounce(function () {
      this.height = this.$refs.body.offsetHeight - 44
    }),
    calcPrice(row) {
      if (!Number.isNaN(Number(row.cost_price)) && !Number.isNaN(Number(row.schedule_count))) {
        row.sale_price = lodash.round(lodash.multiply(Number(row.cost_price), Number(row.schedule_count)), 2)
      }
    },
    blurProduct(index) {
      let row = this.list[index]
      if (!row.prooid && row.product_name) {
        row.tech_info = ""
        row.product_code = ""
        row.band = ""
        this.newPro = true
      }
    },
    changeProduct(index, item) {
      this.$set(this.list[index], 'tech_info', item.tech_info)
      this.$set(this.list[index], 'product_code', item.product_code)
      this.$set(this.list[index], 'band', item.band)
      let obj = JSON.parse(JSON.stringify(item))
      delete obj.oid
      this.assignNewData = obj
      this.newPro = false
    },
    async saveRow(row) {
      if (row.newData) {
        let form = {...row, prjoid: this.$route.query.prj_oid}
        if (this.newPro) {
          Object.assign(form, this.assignNewData)
        }
        fetch("post", "/prj/product/add", form).then(res => {
          if (res.status === 0) {
            this.$message.success("新增成功")
            this.getList()
          } else {
            this.$message.error(res.message)
          }
        })
      } else {
        if (row.pre_product_name !== row.product_name) {
          await fetch("post", "pro/update/onefield", {
            field_id: "pro_core",
            field_name: "product_name",
            field_value: row.product_name,
            new_value: row.product_name,
            oid: row.prooid,
            prooid: row.prooid,
            old_value: row.pre_product_name,
          }).then(res => {
            if (res.status !== 0) {
              this.$message.error("更新产品名称失败：" + res.message)
              return Promise.reject()
            }
          })
        }
        fetch("post", "prj/product/update", row).then(res => {
          if (res.status === 0) {
            this.$message.success("更新成功")
            this.getList()
          } else {
            this.$message.error(res.message)
          }
        })
      }
    },
    listFilter(list) {
      return list.map(item => {
        return {
          ...item,
          edit: false,
          newData: false,
          sale_price: this.handleNum(item.sale_price),
          cost_price: this.handleNum(item.cost_price),
          schedule_count: this.handleNum(item.schedule_count),
        }
      })
    },
    customRow(record) {
      return {
        on: {
          click: () => {
            if (!record.edit && !record.newData) {
              this.detailOid = record.oid
              this.currentProOid = record.prooid
            }
          }
        }
      }
    },
    deleteDialog(oid) {
      this.$confirm({
        title: `确定删除?`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          this.deletePro(oid)
        },
        onCancel() {}
      });
    },
    deletePro(oid) {
      fetch('post', '/prj/product/delete', {
        oid: oid
      }).then((res) => {
        if (res.status === 0) {
          this.$message.success('删除成功！')
          this.currentOid = ""
          this.currentProOid = ""
          this.getList()
        } else {
          this.$message.error(res.message)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.product-quote {
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  overflow: hidden;
  flex-direction: column;
  .body-flex {
    width: 100%;
    flex: 1;
    display: flex;
    overflow: hidden;
    background-color: #f8f8f8;
    .body-left-table {
      width: calc(100% - 480px);
      height: 100%;
      margin-right: 10px;
      background-color: white;
      .blue {
        color: #0059FF;
        cursor: pointer;
      }
      .ant-table-wrapper {
        height: 100%;
        /deep/ th {
          border-bottom: 1px solid #eee;
        }
      }
    }
  }
}
</style>